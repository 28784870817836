/* App.css */
.App {
  margin: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header {
  margin-bottom: 50px;
  font-size: 3rem;
}

.gif-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.load-more {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

button {
  color: #fff;
  background-color: #0f77ea;
  border: none;
  padding: 0.375em 0.75em;
  height: 2.25em;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.gif-item img {
  background-color: #eee;
  margin: 5px;
}